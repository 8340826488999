import moment from 'moment'
import { CONDITION } from './batch'
import { icons } from '@/helpers/icons'

export const DEFAULT_SELECTED_VALUES_FOR_DROPDOWNS = {
  batchTypes: { type: 'sports',  name: 'Sports Trading Cards', id: 'sports' },
  sportsAndGamesBasedOnType: { collx_type_id: null, name: 'Assorted Sports', type: 'sports' },
  titleTemplates: { id: null, name: 'Default CDP Template' },
  descriptionTemplates: { id: null, name: 'Default CDP Template' },
}

export const FORM_OPTIONS = {
  batchTypes: {
    type: 'dropdown',
    label: 'Batch Type',
    placeholder: 'Select batch type',
    key: 'batchTypes',
    field: 'type',
    selected: { type: 'sports',  name: 'Sports Trading Cards', id: 'sports' }
  },
  sportsAndGamesBasedOnType: {
    // label: 'Sport / Game',
    type: 'dropdown',
    label: 'Sport',
    placeholder: 'Select sport/game',
    key: 'sportsAndGamesBasedOnType',
    field: 'collx_type_id',
    selected: { collx_type_id: null, name: 'Assorted Sports', type: 'sports' }
  },
  lockSet: {
    type: 'autocomplete',
    icon: 'heroicons:magnifying-glass',
    label: 'Lock Set',
    activeLabel: 'Pre-fill Set',
    placeholder: 'Search for a set',
    key: 'lockSet',
    field: 'lock_id',
    selected: {}
  },
  includeParallels: {
    type: 'checkbox',
    icon: '',
    label: '',
    activeLabel: 'Include parallels and subsets',
    placeholder: '',
    key: 'includeParallels',
    field: 'lock_includes_parallels',
    selected: true
  },
  titleTemplates: {
    type: 'dropdown',
    label: 'Title Template',
    placeholder: 'Select title template',
    key: 'titleTemplates',
    field: 'title_template_id',
    selected: { id: null, name: 'Default CDP Template' }
  },
  descriptionTemplates: {
    type: 'dropdown',
    label: 'Description Template',
    placeholder: 'Select Description template',
    key: 'descriptionTemplates',
    field: 'description_template_id',
    selected: { id: null, name: 'Default CDP Template' }
  },
}

export const BATCH_TYPES = [
  { type: 'sports',  name: 'Sports Trading Cards', id: 'sports', icon: icons.multisport }, 
  { type: 'tcg', name: 'Trading Cards Games', id: 'tcg', icon: icons.multisport },
  { type: 'nonsport', name: 'Non-Sport Cards', id: 'nonsport', icon: icons.multisport },
]

export const SPORTS_AND_GAMES = [
  { type: 'sports', name: 'Assorted Sports', id: null },
  { type: 'sports', name: 'Baseball', id: 1 },
  { type: 'sports', name: 'Basketball', id: 2 },
  { type: 'sports', name: 'Football', id: 3 },
  { type: 'sports', name: 'Hockey', id: 4 },
  { type: 'sports', name: 'Soccer', id: 5 },
  { type: 'sports', name: 'Wrestling', id: 6 },
  { type: 'sports', name: 'Multisport', id: 10 },
  { type: 'tcg', name: 'Assorted Games', id: null },
  { type: 'tcg', name: 'Pokemon', id: 7 },
  { type: 'tcg', name: 'Magic: The Gathering', id: 8 },
  { type: 'tcg', name: 'Yu-Gi-Oh', id: 9 },
  { type: 'tcg', name: 'Assorted Non-Sports', id: null },
]

export const DEFAULT_BATCH_INFO = {
  description_template_id: null, 
  title_template_id: null, 
  type: 'sports',
  name: '',
  collx_type_id: null,
  condition: CONDITION[0],
  purchase_price: null,
  purchase_date: null,
  start_price: null,
  cabinet: null,
  shelf: null,
  box: null,
  row: null,
  section: null,
  description: null,
  note: '',
  lock_includes_parallels: 1
}