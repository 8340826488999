import { MONTHS } from '@/constants'

export const countOfDaysAgo = (data) => {
  const comparisonDate = new Date(data)
  const currentDate = new Date()

  const timeDifference = currentDate - comparisonDate

  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return daysAgo
}

export const formatDate = (dateString) => {
  const date = new Date(dateString) // Convert to Date object
  const month = MONTHS[date.getUTCMonth()] // Get month abbreviation
  const day = date.getUTCDate() // Get day
  const year = date.getUTCFullYear() // Get year

  return `${month} ${day}, ${year}` // Format the date
}

export const isExpired = (dateString) => {
  const now = new Date() 
  const dateToCheck = new Date(dateString) 

  return dateToCheck < now
}

export const formatDateWithTime = (dateString) => {
  const date = new Date(dateString)
  
  // Format date part
  const month = MONTHS[date.getUTCMonth()]
  const day = date.getUTCDate()
  const year = date.getUTCFullYear()

  // Format time part
  let hours = date.getUTCHours()
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'
  
  // Convert hours to 12-hour format
  hours = hours % 12
  hours = hours ? hours : 12 // Handle midnight (0)
  
  return `${month} ${day}, ${year} • ${hours}:${minutes}:${seconds} ${ampm} EDT`
}
