import { icons } from '@/helpers/icons.js'

export const INVENTORY_FILTERS = {
  status_tabs: {
    selected: 'All',
    options: ['All', 'Available', 'Sold Out', 'Not For Sale'],
  },

  fast_actions: [
    {
      icon: { is_local: true, value: icons.pencil_square },
      name: 'Draft',
      key_name: 'with_draft',
    },
    {
      icon: { is_local: true, value: icons.archive },
      name: 'Archived Edits',
      key_name: 'with_archived',
    },
  ],

  filter_blocks: [
    [
      {
        icon: { is_local: true, value: icons.status_spinner },
        name: 'Status',
        key_name: 'status',
      },
    ],
    [
      {
        icon: { is_local: true, value: icons.icon_multisport },
        name: 'Categories',
        field: 'categories',
        key_name: 'categories',
      },
      {
        icon: 'heroicons:tag',
        name: 'Brand',
        field: 'brand',
        key_name: 'brand',
      },
      {
        icon: 'heroicons:information-circle',
        name: 'Attribute',
        field: 'attributes',
        key_name: 'attribute',
      },
      {
        icon: 'heroicons:scale-20-solid',
        name: 'Graded',
        field: 'graded',
        key_name: 'graded',
      },
      {
        icon: 'heroicons:scale-20-solid',
        name: 'Grader',
        field: 'grader',
        key_name: 'grader',
      },
      // { icon: 'heroicons:currency-dollar', name: 'Price' },
      // { icon: 'heroicons:map-pin', name: 'Location' },
    ],
  ],
}

export const INVENTORY_SORT_OPTIONS = [
  {
    label: 'SKU',
    value: 'number',
  },
  {
    label: 'Recently added',
    value: 'createdAt.desc',
  },
  {
    label: 'Oldest to newest',
    value: 'createdAt.asc',
  },
  {
    label: 'Last modified',
    value: 'updatedAt.desc',
  },
  {
    label: 'Status A-Z',
    value: 'status.asc',
  },
  {
    label: 'Status Z-A',
    value: 'status.desc',
  },
  {
    label: 'Sale price high to low',
    value: 'salePrice.desc',
  },
  {
    label: 'Sale price low to high',
    value: 'salePrice.asc',
  },
  {
    label: 'Name A-Z',
    value: 'player.asc',
  },
  {
    label: 'Name Z-A',
    value: 'player.desc',
  },
  {
    label: 'Batch id A-Z',
    value: 'team.asc',
  },
  {
    label: 'Batch id Z-A',
    value: 'team.desc',
  },
]

export const INVENTORY_CARD_COLUMN_OPTIONS = [
  { checked: true, field: 'name', name: 'Product' },
  { checked: true, field: 'sku', name: 'SKU' },
  { checked: true, field: 'status', name: 'Status' },
  { checked: true, field: 'inventory', name: 'Inventory' },
  { checked: true, field: 'listings', name: 'Listings' },
  { checked: true, field: 'sale_price', name: 'Sale Price' },
  { checked: true, field: 'sport', name: 'Category' },
  { checked: true, field: 'batch_id', name: 'Batch ID' },
  { checked: false, field: 'year', name: 'Year' },
  { checked: false, field: 'brand', name: 'Brand' },
  { checked: false, field: 'set', name: 'Set' },
  { checked: false, field: 'player', name: 'Name' },
  { checked: false, field: 'card_number', name: 'Card No.' },
  { checked: false, field: 'subset', name: 'Subset/Parallel' },
  { checked: false, field: 'attributes', name: 'Attributes' },
  { checked: false, field: 'team', name: 'Team', eBay: true, shopify: true },
  { checked: false, field: 'variation', name: 'Variation' },
  { checked: false, field: 'graded', name: 'Graded' },
  { checked: false, field: 'grader', name: 'Grader' },
  { checked: false, field: 'condition', name: 'Condition' },
  { checked: false, field: 'cert_no', name: 'Cert No. ' },
  { checked: false, field: 'grade_number', name: 'Grade' },
  { checked: false, field: 'purchase_date', name: 'Purchase Date' },
  { checked: false, field: 'purchase_price', name: 'Purchase Price' },
  { checked: false, field: 'note', name: 'Note' },
  { checked: false, field: 'cabinet', name: 'Cabinet' },
  { checked: false, field: 'shelf', name: 'Shelf' },
  { checked: false, field: 'box', name: 'Box' },
  { checked: false, field: 'row', name: 'Row' },
  { checked: false, field: 'section', name: 'Section' }
]

export const ICONS_RELATED_TO_STATUS = {
  ready_to_list: 'ready_to_list',
  draft: 'pencil_square',
  available: 'ready_to_list',
  ended: 'stop',
  draft: 'pencil_square',
  sold_out: 'ready_to_list',
  archive: 'archive',
  accepting_offers: 'shopping_cart',
  for_sale: 'shopping_cart',
  sold_on: 'ready_to_list',
  active: 'ready_to_list',
  canceled: 'ready_to_list',
  // ended: 'ready_to_list',
  ended_with: 'ready_to_list',
  ended_without: 'ready_to_list',
  inactive: 'ready_to_list',
  for_sale: 'shopping_cart'
}

// export const INVENTORY_STATUSES = ['available', 'for_sale', 'ended', 'draft', 'sold_out', 'archive']
export const INVENTORY_STATUSES = ['for_sale', 'ended']

export const DATABASE_MATCH_FIELDS_FOR_VIEW = [
  {
    field: 'year',
    label: 'Year',
    type: 'number',
  },
  {
    field: 'brand',
    label: 'Brand',
    type: 'text',
  },
  {
    field: 'set',
    label: 'Set',
    type: 'text',
  },
  {
    field: 'card_number',
    label: 'Card No.',
    type: 'number',
  },
  {
    field: 'player',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'subset',
    label: 'Subset / Parallel',
    type: 'text'
  },
  {
    field: 'variation',
    label: 'Variation',
    type: 'text',
  },
  {
    field: 'team',
    label: 'Team',
    type: 'text',
  }
]

export const LOCATIONS_FIELDS = [
  {
    label: 'Cabinet',
    field: 'cabinet'
  },
  {
    label: 'Shelf',
    field: 'shelf'
  },
  {
    label: 'Box',
    field: 'box'
  },
  {
    label: 'Raw',
    field: 'raw'
  },
  {
    label: 'Section',
    field: 'section'
  },
]

export const ATTRIBUTES = {
  sports: ['Rookie', 'Autographed', 'Memorabilia'],
  pokémon: ['Amazing Rare', 'Classic Collection', 'Code Card', 'Common', 'Holo Rare', 'Prism Rare', 'Promo', 'Rare', 'Rare Ace', 'Rare BREAK', 'Secret Rare', 'Shiny Holo Rare', 'Ultra Rare', 'Uncommon'],
  'magic: the gathering': ['Common', 'Land', 'Mythic', 'Promo', 'Rare', 'Special', 'Token', 'Uncommon'],
  'yu-gi-oh': [
    '10000 Secret Rare', 'Collector’s Rare', 'Common / Short Print', 'Duel Terminal Technology Common', 'Duel Terminal Technology Ultra Rare’',
    'Ghost Rare', 'Gold Rare', 'Gold Secret Rare', 'Mosaic Rare', 'Premium Gold Rare', 'Promo', 'Rare',
    'Secret Pharaoh’s Rare', 'Secret Rare’', 'Shatterfoil Rare', 'Starfoil Rare', 'Starlight Rare', 'Super Rare',
    'Ultimate Rare', 'Ultra Pharaoh’s Rare', 'Ultra Rare'
  ],
  'non-sport': ['Autographed', 'Memorabilia']
}
