import { useUserStore } from '@/stores/UserStore'
import { useBatchStore } from '@/stores/BatchStore'
import { useConnectionStore } from '@/stores/ConnectionStore'
import { useInventoryStore } from '@/stores/InventoryStore'
import { useListingsStore } from '@/stores/ListingsStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useEbayLocationsStore } from '@/stores/EbayLocationsStore'
import { INVENTORY_CARD_COLUMN_OPTIONS } from '@/constants'
import { getConnections, getChannelTypes } from '@/helpers/connectionsAndExport.js'

export const handleAccountCreation = async () => {
  const userStore = useUserStore()

  if (userStore.user?.status === 'active') {
    return '/batches'
  }

  return true
}

export const handleBatchAdd = async () => {
  const batchStore = useBatchStore()

  if (batchStore.config?.subscription && !batchStore.config?.hasActiveSubscription) {
    return '/batches'
  }

  return true
}

export const handleCreateBatch = async () => {
  const batchStore = useBatchStore()

  if (batchStore.config?.subscription && !batchStore.config?.hasActiveSubscription) {
    return '/batches'
  }

  return true
}

export const handleConnections = async (to) => {
  const connectionStore = useConnectionStore()
  const userStore = useUserStore()
  const { type, listingType } = getChannelTypes(to.fullPath)

  if ((to.fullPath ===  '/connections/ebay/connected' || to.fullPath ===  '/connections/ebay/connect') && userStore.user?.ebay_profile?.individualAccount) {
    return '/connections/ebay'
  }

  if ((to.fullPath ===  '/connections/whatnot/connected' || to.fullPath ===  '/connections/whatnot/connect') && userStore.user?.whatnot_profile?.individualAccount) {
    return '/connections/whatnot'
  }

  await connectionStore.fetchTemplates(type, listingType)

  if (listingType === 'ebay') {
    connectionStore.fetchTemplates('marketplaceListingTemplates', 'ebay-direct')
  } else if (listingType === 'whatnot') {
    connectionStore.fetchTemplates('whatnotListingTemplates', 'whatnot')
  }

  connectionStore.templateCreation = true
  return true
}

export const handleWhatnotMarketplace = async () => {
  const connectionStore = useConnectionStore()
  connectionStore.isItEbayConnection = false
  connectionStore.isEbayDirect = false

  return true
}

export const handleEbayMarketplace = async () => {
  const connectionStore = useConnectionStore()
  connectionStore.isItEbayConnection = true
  connectionStore.isEbayDirect = true

  await connectionStore.optPolicies()
  connectionStore.createDefaultEbayPolicies()

  return true
}

export const handleEbayTemplates = async () => {
  const connectionStore = useConnectionStore()
  connectionStore.isItEbayConnection = false
  connectionStore.isEbayDirect = false

  return true
}

export const handleInventory = () => {
  const inventoryStore = useInventoryStore()
  const userStore = useUserStore()

  const displayCol = localStorage.getItem("display_inventory_columns") ?
                        JSON.parse(localStorage.getItem("display_inventory_columns"))[userStore.user.id] ?
                          [ ...JSON.parse(localStorage.getItem("display_inventory_columns"))[userStore.user.id]] : [...INVENTORY_CARD_COLUMN_OPTIONS]
                        : [...INVENTORY_CARD_COLUMN_OPTIONS]

  inventoryStore.columnOptions = displayCol
}

export const handleListingsDetails = async (to) => {
  const userStore = useUserStore()
  const listingsStore = useListingsStore()

  const connections = getConnections(userStore.user)

  if (connections.length && !to.params.connection) {
    const activeConnections = connections.filter(item => item.active)
    listingsStore.activeConnectionData = connections ? activeConnections[0] : null

    return `/listings/${listingsStore.activeConnectionData.key}/${to.params.cardId}`
  }

  if (to.params.connection) {
    listingsStore.activeConnectionData = connections.find(item => item.key === to.params.connection)
  }

  return true
}

export const handleListings = async (to) => {
  const userStore = useUserStore()
  const listingsStore = useListingsStore()

  const connections = getConnections(userStore.user)

  if (connections.length && !to.params.connection) {
    const activeConnections = connections.filter(item => item.active)
    listingsStore.activeConnectionData = connections ? activeConnections[0] : null

    return `/listings/${listingsStore.activeConnectionData.key}`
  }

  if (to.params.connection) {
    listingsStore.activeConnectionData = connections.find(item => item.key === to.params.connection)
  }

  return true
}

export const handleEbayLocations = async () => {
  const ebayLocationsStore = useEbayLocationsStore()

  await ebayLocationsStore.fetchLocations()

  return true
}

export const handleUtm = async (userStore, to) => {
  const globalStore = useGlobalStore()
  const utm = localStorage.getItem('utm') && localStorage.getItem('utm') !== 'undefined'  ? JSON.parse(localStorage.getItem('utm')) : null
  let utmQueryData = null

  if (Object.keys(to.query).length) {
    utmQueryData = Object.entries(to.query).reduce((acc, [key, item]) => {
      if (key.includes('utm')) {
        acc[key] = item
      }

      return acc
    }, {})
  }

  try {
    if (!utm && utmQueryData && Object.keys(utmQueryData).length) {

      const data = await globalStore.createUtmUser(utmQueryData)
  
      localStorage.setItem('utm', JSON.stringify(data)) 
    } else {
      const isUtmNoNeedToUpdate = JSON.stringify(utm) === JSON.stringify(utmQueryData)
      const preparedUtmToUpdate = JSON.stringify(utm) === JSON.stringify(utmQueryData) ? utm : utmQueryData

      if (!preparedUtmToUpdate || !utm) return
      const data = await globalStore.updateUtmUser(utm.id, preparedUtmToUpdate)
      localStorage.setItem('utm', JSON.stringify(data))    }
  } catch (error) {
    console.log('error createUtmUser or updateUtmUser', error)
  }
}

export const handleBeforeEach = (to) => {
  const userStore = useUserStore()
  const batchStore = useBatchStore()
  batchStore.loading = true // XXX FIXME HACK
  
  handleUtm(userStore, to)

  if (to.meta.requireAuth) {
    if (!userStore.loggedIn) {
      userStore.redirectTo = to.fullPath
      return '/sign-in?backTo=' + to.fullPath
    }
  }

  if (to.meta.requireFields) {
    if (userStore.user?.status === 'pending') {
      return '/account-creation'
    }
  }
}

export const checkAuthUserFirstNameAndLastName = (to) => {
  const userStore = useUserStore()

  if (!userStore.user.first_name && !userStore.user.last_name) {
    return '/account-creation/user'
  }

  return true
}
