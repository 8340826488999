import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Icon } from '@iconify/vue'
import vClickOutside from 'click-outside-vue3'
import veProgress from "vue-ellipse-progress"
import VueLazyLoad from 'vue3-lazyload'
import { QuillEditor } from '@vueup/vue-quill'
import posthog from "posthog-js";
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

import { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import brokenImage from '@/assets/images/broken-portrait.png'

import App from './App.vue'
import router from './router'

/* Global css code */
import './css/main.css'

import { SentryInit } from '@/services/sentry'
import axios from 'axios'

const SECRET = '2MwNwftfYfzyPMAvG2CtO977STCQCgpRfLVYaPPHC27UeeNkkqjAf1vP+ue1jJ3sN6MqvZ+ubz8vaBDlPz14aaL+NDg8dp2tvoZ1kOt6tSSxE6fB4vdONX2THkVZZVtvGey00tHnHj8y28lHzJ7WVYIZJT9/oR7y7J1fZSLmhjl5CDdQEbqU6mynm2Y7TkyGnVlORORCyvPthFUd/O7TxR/tIMnfeeUR9p5glPC1t3x0l5tHBG1OF3ohSqjquh1XT3nfrCAeD6qUZm832XhUPRYIFXEnsj5vkO+6Y3nbZDg78g5GZgYL3KDQ/M7EvmrJOn7EOZr+8Sp7FcZVTmlIskEwxWp4ZecmNfPWrJbS80PXuM15Ij8KVpklrKuF0YnEUi3bS20oclRgFWfUxMZR8xYT/tfCq/ZYjAePOM39DCitDDJIcgQvJSJyoRKVppsu35zwBo5nil4JzXFoAbXXH3RdlaK0R/SnRZjgABPPKMm6+piN/wem33LSoO/2iFo8bMEbtO+/JUqAxe+ueN3rVaj06ISXv62qhjqBnCTVq6ZXUVhtkCqjxGAHUg2083VWUYYweEr3RMRQO+W0Pr0ptJBRkG1aXF2eVMqh0l+ZMS6cKekoJ7VoJTMfM0djs+AtaUkq+L/ZfB3hKR1lR+mm8CxzunjUZ4qcB3t9c92O1AM='

/* Init Vue app */
const app = createApp(App)

const currentURL = window.location.href;

const isLocalOrDev = window.location.hostname === 'localhost' || window.location.hostname.includes('dev')

// app.config.errorHandler = (err, instance, info) => {
//   console.error('Vue Global Error:', err, info)
  
//   if (err instanceof RangeError && (window.location.hostname === 'localhost' || window.location.hostname.includes('dev'))) {
//     console.warn('Caught a RangeError:', err)
//     alert('An error occurred: ' + err.message) 
//   }
// }

/* Init Sentry */
!currentURL.includes('localhost') && SentryInit(app)

/* Init Posthog */
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  autocapture: false,
  disable_session_recording: isLocalOrDev ? true : false,
});

/* Init Iconify component */
app.component('Icon', Icon)

app.component('QuillEditor', QuillEditor)

/* Create Vue app */
app.use(router)
app.use(createPinia())
app.use(vClickOutside)
app.use(veProgress)
app.use(VueLazyLoad, {
  lifecycle: {
    error: (el) => {
      const dataImage = el.getAttribute('data-image')
      const imgWithDataId = document.querySelector(`img[data-image="${dataImage}"]`)

      if (imgWithDataId) {
        imgWithDataId.src = brokenImage
      }
    }
  }
})
app.use(ToastPlugin)

app.directive('tooltip', VTooltip)
app.provide("posthog", posthog)

app.mount('#app')
