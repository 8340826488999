import { defineStore, acceptHMRUpdate } from 'pinia'
import { apiClient } from '@/helpers/api.js'
import { useConnectionStore } from '@/stores/ConnectionStore'
import { LISTING_STATUS, POLICIES_IDS_NAMES_IN_TEMPLATE } from '@/constants'

const available_filters = {
  attribute: [],
  brand: [],
  category: [],
  graded: [],
  grader: [],
  team: []
}

export const useListingsStore = defineStore('ListingsStore', {
  state: () => {
    return {
      activeConnectionData: null,
      searchActive: false,
      searchQuery: '',
      listingsLoading: false,
      listingsLoadingMore: false,
      correctListingsLoader: false,
      listingsView: 'List',
      openedListingDialog: false,
      openFullScreenImage: false,
      imageForFullScreen: null,
      selectedCardData: null,
      selectedCardDataWithBeforeUpdate: {},
      itemFilters: {},
      listings: [],
      originalListings: [],
      availableFilters: {},
      endedListings: [],
      totalListings: {
        for_sale: 0,
        ended: 0
      },
      pages: {
        for_sale: 1,
        ended: 1
      },
      count: {
        collx: 0,
        ebay: 0,
        whatnot: 0,
        shopify: 0,
      },
      nextListingId: null,
      previousListingId: null,
      isDirty: false,
      visibleListingList: ''
    }
  },

  getters: {
    listingsByStatus () {
      return {
        for_sale: { key: 'for_sale', values: this.listings },
        ended: { key: 'ended', values: this.endedListings }
      }
    }
  },

  actions: {
    async fetchListings(type, section = 'for_sale', isPagination = false) {
      try {
        // Set appropriate loading state based on pagination
        if (this.pages[section] > 1) {
          this.listingsLoadingMore = true
        } else {
          this.listingsLoading = true
        }

        let listingList = { data: { available_filters } }
        let endedListingList = { data: { available_filters } }

        delete this.itemFilters.status

        if (!this.visibleListingList || this.visibleListingList === 'for_sale') {
          if (this.visibleListingList === 'for_sale') {
            this.endedListings = []
          }

          // Base parameters for API request
          const baseParams = {
            page: this.pages[section],
            perPage: 10,
            type
          }

          // Combine all filters into a single filter parameter with comma separation, excluding 'q'
          const filterParams = Object.entries(this.itemFilters).length 
            ? `filter=${Object.entries(this.itemFilters)
                .filter(([key]) => key !== 'q')
                .map(([key, value]) => `${key}=${value}`)
                .join(',')}`
            : ''

          const url = `/listings${filterParams ? '?' + filterParams : ''}${
            Object.keys(baseParams).length ? (filterParams ? '&' : '?') + new URLSearchParams({
              ...baseParams,
              ...(this.itemFilters.q ? { q: this.itemFilters.q } : {})
            }).toString() : ''
          }`

          // Fetch active listings
          listingList = await apiClient().get(url)

          // Process active listings data
          const updatedListings = listingList.data.items.reduce((acc, item) => {
            // Merge card and listing data, add status fields
            acc.push({ 
              ...item.card, 
              ...item.listing, 
              status: item.card.status, 
              listingStatus: LISTING_STATUS.for_sale.key 
            })
            return acc
          }, [])

          // Update count state which we show on sidebar menu
          this.count = Object.entries(this.count).reduce((acc, [key, value]) => {
            acc[key] = listingList.data[`${key}_listings_active`] + listingList.data[`${key}_listings_inactive`]
            return acc
          }, {})

          // Update active listings state - either append or replace
          this.listings = isPagination 
            ? [...this.listings, ...updatedListings] 
            : updatedListings

          this.totalListings.for_sale = listingList.data.total_listings
        }


        if (!this.visibleListingList || this.visibleListingList === 'ended') {
          if (this.visibleListingList === 'ended') {
            this.listings = []
          }
          // Construct URL for ended listings using same filters
          const endedFilterParams = Object.entries(this.itemFilters).length
          ? `filter=${Object.entries(this.itemFilters)
              .filter(([key]) => key !== 'q')
              .map(([key, value]) => `${key}=${value}`)
              .join(',')}`
          : ''

          const endedBaseParams = {
            ended: true,
            perPage: 10,
            page: this.pages[section],
            type
          }

          const endedUrl = `/listings${endedFilterParams ? '?' + endedFilterParams : ''}${
          Object.keys(endedBaseParams).length ? (endedFilterParams ? '&' : '?') + new URLSearchParams({
            ...endedBaseParams,
            ...(this.itemFilters.q ? { q: this.itemFilters.q } : {})
          }).toString() : ''
          }`

          // Fetch ended listings
          endedListingList = await apiClient().get(endedUrl)

          // Process ended listings data
          const updatedEndedListings = endedListingList.data.items.reduce((acc, item) => {
            // Merge card and listing data, add status fields
            acc.push({ 
              ...item.card, 
              ...item.listing, 
              status: item.card.status, 
              listingStatus: LISTING_STATUS.ended.key 
            })
            return acc
          }, [])
          
          // Update ended listings state - either append or replace
          this.endedListings = isPagination
            ? [...this.endedListings, ...updatedEndedListings]
            : updatedEndedListings

          this.totalListings.ended = endedListingList.data.total_listings
        }

        this.availableFilters = {
          attribute: [ ...listingList.data.available_filters?.attribute, ...endedListingList.data.available_filters?.attribute ],
          brand: [ ...listingList.data.available_filters?.brand, ...endedListingList.data.available_filters?.brand ],
          category: [ ...listingList.data.available_filters?.category, ...endedListingList.data.available_filters?.category ],
          graded: [ ...listingList.data.available_filters?.graded, ...endedListingList.data.available_filters?.graded ],
          grader: [ ...listingList.data.available_filters?.grader, ...endedListingList.data.available_filters?.grader ],
          team: [ ...listingList.data.available_filters?.team, ...endedListingList.data.available_filters?.team ]
        }

        // Reset loading states
        this.listingsLoading = false
        this.listingsLoadingMore = false

      } catch (error) {
        console.error('fetchListings error', error)
        // Reset loading states on error
        this.listingsLoading = false
        this.listingsLoadingMore = false
      }
    },

    async fetchListingById(type, id) {
      const connectionStore = useConnectionStore()
      try {
        this.listingsLoading = true

        const { data } = await apiClient().get(`/listings/${id}?type=${type}`)

        const listingStatus = data.listing.quantity_available > 0 ? LISTING_STATUS.for_sale.key : data.listing.quantity_sold > 0 ? LISTING_STATUS.ended.key : LISTING_STATUS.for_sale.key 

        this.selectedCardData = {
          ...data,
          ...data.card, 
          ...data.listing, 
          status: data.card.status, 
          listingStatus,
          listingId: data.listing.id,
          attributes: data.card.flags,
        }

        this.selectedCardDataWithBeforeUpdate = { ...this.selectedCardData }

        connectionStore.selectedTemplate = {
          ebay_location_key: this.selectedCardData.location_key,
          ebay_location_id: this.selectedCardData.location_id,
          [POLICIES_IDS_NAMES_IN_TEMPLATE.fulfillmentPolicies]: this.selectedCardData.fulfillment_id,
          [POLICIES_IDS_NAMES_IN_TEMPLATE.paymentPolicies]: this.selectedCardData.payment_id,
          [POLICIES_IDS_NAMES_IN_TEMPLATE.returnPolicies]: this.selectedCardData.return_id,
        }

        this.nextListingId = data.prev_listing_id
        this.previousListingId = data.next_listing_id

        this.listingsLoading = false

        this.fetchListings(type, listingStatus)
      } catch (error) { 
        console.error('fetchListingById error', error)
        this.listingsLoading = false
      }
    },

    async updateListing(body) {
      try {
        this.selectedCardData = { ...this.selectedCardData, ...body }

        await apiClient().patch(`/listings/${this.selectedCardData.listingId}`, body) 
      } catch (error) {
        console.error('updateListing error', error)
      }
    },

    async publishListing() {
      try {
        let updatedData = {}

        if (this.activeConnectionData.key === 'ebay') {
          updatedData = {
            ebay_price: this.selectedCardData.ebay_price,
            ebay_title: this.selectedCardData.ebay_title,
            ebay_description: this.selectedCardData.ebay_description,
            schedule_date: this.selectedCardData.schedule_date,
            schedule_time: this.selectedCardData.schedule_time
         }
        } else {
          updatedData = {
            collx_price: this.selectedCardData.collx_price,
            collx_notes: this.selectedCardData.collx_description,
         }
        }
        const body = {
          data:{
              type: this.activeConnectionData.key,
              listing_id: this.selectedCardData.listingId,
              updated_data: updatedData
          }
      }

        await apiClient().post(`/jobs/update-listing`, body)       
      } catch (error) {
        console.error('publishListing error', error)
      }
    },

    async endListing(type) {
      try {
        await apiClient().post(`/jobs/send-to-${type}`, {
          cards: [
            this.selectedCardData.card.id
          ],
          data: {
            job_method: 'delete'
          }
        })
      } catch (error) {
        console.error('endListing error', error)
      }
    },

    async correctListings(type) {
      try {
        this.correctListingsLoader = true

        await apiClient().post(`/${type}/notifications`)
        await apiClient().post(`/${type}/correct-listings`)

        this.fetchListings(type)

        this.correctListingsLoader = false
      } catch (error) {
        console.error('correctListings error', error)
        this.correctListingsLoader = false
      }
    },

  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useListingsStore, import.meta.hot))
}