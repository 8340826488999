import { defineStore, acceptHMRUpdate } from 'pinia'
import { INVENTORY_CARD_COLUMN_OPTIONS, TCG_CONDITION, CONDITION } from '@/constants'
import { useUserStore } from '@/stores/UserStore'
import { useBatchStore } from '@/stores/BatchStore'

export const useInventoryStore = defineStore('InventoryStore', {
  state: () => {
    return {
      inventoryLoading: false,
      cards: [
        {
          "id":3717548,
          "viewed":true,
          "inventory": 2,
          "batch_id": 'Prospects vol2',
          "is_loading":false,
          "has_errors":false,
          "errors":[],
          "has_banner":0,
          "has_suggested_edits":false,
          "suggested_edits":[],
          "green":true,
          "yellow":false,
          "red":false,
          "select":false,
          "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
          "back_image":null,
          "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
          "back_image_sm":null,
          "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
          "back_image_md":null,
          "title":"1990 Frank Thomas Chicago White Sox",
          "template_title":"1990 Frank Thomas Chicago White Sox",
          "template_title_id":15964,
          "collx_type_id": 1,
          "template_description_id":null,
          "database_match":{
            "id":202794,
            "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
            "back_image":null,
            "title":"1990 Score Rookie & Traded #86T Frank Thomas",
            "card_number":"86T",
            "player":"Frank Thomas",
            "year":1990,
            "set":"1990 Score Rookie & Traded",
            "setId":4337,
            "brand":"Score",
            "subset":null,
            "variation":null,
            "serialNumber":null
          },
          "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"available","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":1,"grader":'PSA',"condition":"Near Mint or Better","cert_no":4345345345,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":true, ebay_listed_at: true, shopify_listed_at: false},
          {
            "id":3717541,
            "viewed":true,
            "inventory": 1,
            "is_loading":false,
            "batch_id": 'CollX Import',
            "has_errors":false,
            "errors":[],
            "has_banner":0,
            "has_suggested_edits":false,
            "suggested_edits":[],
            "green":true,
            "yellow":false,
            "red":false,
            "select":false,
            "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
            "back_image":null,
            "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
            "back_image_sm":null,
            "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
            "back_image_md":null,
            "title":"1991 Frank Thomas Chicago White Sox",
            "template_title":"1991 Frank Thomas Chicago White Sox",
            "template_title_id":15964,
            "template_description_id":null,
            "database_match":{},
            "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"ended","start_price":3,"collx_match_id":null,"sport":"Yu-Gi-Oh","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":'Gold Rare',"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"tcg","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
            {
              "id":3717542,
              "viewed":true,
              "inventory": 2,
              "is_loading":false,
              "batch_id": 'MTrout 2',
              "has_errors":false,
              "errors":[],
              "has_banner":0,
              "has_suggested_edits":false,
              "suggested_edits":[],
              "green":true,
              "yellow":false,
              "red":false,
              "select":false,
              "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
              "back_image":null,
              "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
              "back_image_sm":null,
              "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
              "back_image_md":null,
              "title":"1992 Frank Thomas Chicago White Sox",
              "template_title":"1992 Frank Thomas Chicago White Sox",
              "template_title_id":15964,
              "template_description_id":null,
              "database_match":{
                "id":202794,
                "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                "back_image":null,
                "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                "card_number":"86T",
                "player":"Frank Thomas",
                "year":1990,
                "set":"1990 Score Rookie & Traded",
                "setId":4337,
                "brand":"Score",
                "subset":null,
                "variation":null,
                "serialNumber":null
              },
              "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"draft","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
              {
                "id":3717543,
                "viewed":true,
                "inventory": 3,
                "is_loading":false,
                "has_errors":false,
                "batch_id": 'MTrout 2',
                "errors":[],
                "has_banner":0,
                "has_suggested_edits":false,
                "suggested_edits":[],
                "green":true,
                "yellow":false,
                "red":false,
                "select":false,
                "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
                "back_image":null,
                "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
                "back_image_sm":null,
                "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
                "back_image_md":null,
                "title":"1993 Frank Thomas Chicago White Sox",
                "template_title":"1993 Frank Thomas Chicago White Sox",
                "template_title_id":15964,
                "template_description_id":null,
                "database_match":{
                  "id":202794,
                  "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                  "back_image":null,
                  "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                  "card_number":"86T",
                  "player":"Frank Thomas",
                  "year":1990,
                  "set":"1990 Score Rookie & Traded",
                  "setId":4337,
                  "brand":"Score",
                  "subset":null,
                  "variation":null,
                  "serialNumber":null
                },
                "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"sold_out","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
                {
                  "id":3717544,
                  "viewed":true,
                  "inventory": 2,
                  "is_loading":false,
                  "has_errors":false,
                  "errors":[],
                  "batch_id": 'MTrout 2',
                  "has_banner":0,
                  "has_suggested_edits":false,
                  "suggested_edits":[],
                  "green":true,
                  "yellow":false,
                  "red":false,
                  "select":false,
                  "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
                  "back_image":null,
                  "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
                  "back_image_sm":null,
                  "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
                  "back_image_md":null,
                  "title":"1994 Frank Thomas Chicago White Sox",
                  "template_title":"1994 Frank Thomas Chicago White Sox",
                  "template_title_id":15964,
                  "template_description_id":null,
                  "database_match":{
                    "id":202794,
                    "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                    "back_image":null,
                    "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                    "card_number":"86T",
                    "player":"Frank Thomas",
                    "year":1990,
                    "set":"1990 Score Rookie & Traded",
                    "setId":4337,
                    "brand":"Score",
                    "subset":null,
                    "variation":null,
                    "serialNumber":null
                  },
                  "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"archive","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
        ],
      columnOptions: [...INVENTORY_CARD_COLUMN_OPTIONS],
      pagination: null,
      selectedCards: {},
      selectedRanges: null,
      selectedCardData: null,
      searchActive: false,
      searchQuery: '',
      openFullScreenImage: false,
      imageForFullScreen: null,
    }
  },

  getters: {
    condition() {
      return this.selectedCardData?.type === 'tcg' ? [...TCG_CONDITION] : [...CONDITION]
    },
    grade_number() {
      const batchStore = useBatchStore()
      let grade = []

      if (!this.selectedCardData) return grade

      if (Object.keys(this.selectedCardData).length) {
        const grader = batchStore.config.graders?.find(item => item.name === this.selectedCardData?.grader)
        
        if (!grader) return grade

        grade = grader.grading_scale.reduce((acc, item) => {
          acc.push({ name: item, id: item })

          return acc
        }, [])
      }
     
      return grade
    },
  },

  actions: {
    updateToDefault() {
      this.columnOptions = INVENTORY_CARD_COLUMN_OPTIONS
    },

    updateColumnOption(option) {
      const userStore = useUserStore()
      this.columnOptions = this.columnOptions.reduce((acc, item) => {
        item.field === option.field ? acc.push({...item, checked: !item.checked}) :  acc.push(item)
      
        return acc
      }, [])

      const displayColumns = JSON.parse(localStorage.getItem("display_inventory_columns")) || {}
      localStorage.setItem("display_inventory_columns", JSON.stringify({ ...displayColumns, [userStore.user.id]: this.columnOptions }))
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInventoryStore, import.meta.hot))
}