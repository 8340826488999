export default [
  {
    path: '/',
    name: 'Homepage',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Home/index.vue')
  },

  {
    path: '/changelog',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Home/Changelog/index.vue')
  },
  {
    path: '/cdp-hub/:feedId',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Home/components/Hub/FeedDetails.vue')
  },
  {
    path: '/settings',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Settings/index.vue'),
    redirect: '/settings/account',
    children: [
      { path: 'account', component: () => import('@/views/Settings/Account.vue') },
      { path: 'plans', component: () => import('@/views/Settings/Plans.vue') },
      { path: 'inventory', component: () => import('@/views/Settings/Inventory.vue') },
      { path: 'notifications', component: () => import('@/views/Settings/Notifications.vue') },
      { path: 'custom-fields', component: () => import('@/views/Settings/CustomFields.vue') },
      { path: 'templates', component: () => import('@/views/Settings/Templates.vue') },
    ]
  },
  {
    path: '/refer-friend',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/feedback',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/help',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/billing',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/desktop-app',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/pricing',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue')
  },
  {
    path: '/exception-detected',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/ExceptionDetectedPage.vue'),
  },
  {
    path: '/maintenance',
    component: () => import('@/views/MaintenancePage.vue'),
  }
]
