import { cleanStatus } from '@/helpers/text'
import { formatDate } from '@/helpers/times'
import { useBatchStore } from '@/stores/BatchStore'

export const isValidEmail = (email) => {
  if (email) {
    return email.match(
      /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i
    )
  } else {
    return false
  }
}

export const isValidPassword = (password) => {
  if (password) {
    return password.match(
      /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$/gm
    )
  } else {
    return false
  }
}

const returnTips = (content) => {
  return Object.keys(content).reduce((acc, key) => {
    acc.push(`${content[key]}`)

    return acc
  }, [])
}

export const tooltipContentForStateCell = (errors, suggestedEdits) => {
  let tipsErrors = []
  let tipsSuggestedEdits = []

  if (errors && Object.keys(errors).length) {
    tipsErrors = returnTips(errors)
  }
  if (suggestedEdits && Object.keys(suggestedEdits).length) {
    tipsSuggestedEdits = returnTips(suggestedEdits)
  }

  return [...tipsErrors, ...tipsSuggestedEdits]
}

export const tooltipContentPerEachField = (errors, suggestedEdits, field) => {
  let tips = []
  if (errors && Object.keys(errors).includes(field)) {
    return `${errors[field]}`
  }
  if (suggestedEdits && Object.keys(suggestedEdits).includes(field)) {
    return `${suggestedEdits[field]}`
  }

  return ''
}

export const setToLocalStorageByUserId = (data, key, userId) => {
  const savedValues = JSON.parse(localStorage.getItem(key)) || {}
  const updatedValues = JSON.stringify({ ...savedValues, [userId]: data })
  localStorage.setItem(key, updatedValues)
}

export const getImageUrl = (iconName) => {
  return new URL(`../assets/3d-assets/icons/${iconName}`, import.meta.url).href
}
export const getCellClassRules = (field) => {
  return {
    '!border !border-r-[1px] !border-red-500 !border-r-red-500 !border-l-red-500 !border-t-red-500 !border-b-red-500': (params) => {
      return params.data?.errors && params.data?.errors[field]
    },
    '!border !border-r !border-yellow-500 !border-r-yellow-500 !border-l-yellow-500 !border-t-yellow-500 !border-b-yellow-500': (params) => {
      return (params.data?.errors && !params.data?.errors[field] && params.data?.suggested_edits[field])
    },
    '!text-gray-400 !pointer-events-none': (params) => {
      const batchStore = useBatchStore()

      return ['set', 'subset'].includes(field) && batchStore.selectedBatch?.lock_id
    }
  }
}

// XXX Update to batch.stats.total when available in api
export const maxPageCards = (windowHeight) => Math.floor((windowHeight - 160) / 40)

export const getSpreadSheetHeight = (items, windowHeight) => {
  let heightValue = 0
  if (items.length >= maxPageCards(windowHeight)) {
    heightValue = windowHeight - 160
  } else {
    heightValue = ((items.length + 1) * 40) + 26
  }
  return {
    height: heightValue + 'px',
  }
}

export const isMobile = () => {
  return /Mobi|Android|iP(hone|od|ad)|IEMobile|BlackBerry|Opera Mini|Mobile|Silk/.test(navigator.userAgent)
}

export const retrieveLocalStorageCards = (user) => {
  if (user) {
    const dismissedCardDataJSON = localStorage.getItem(`dismissedCoaching${user.id}`);
    return dismissedCardDataJSON ? JSON.parse(dismissedCardDataJSON) : null;
  }
  return null
}

export const getNextPaymentTextContentForSidebar = (config = {}) => {
  const expirationDate = config.subscription?.expiration_date
  const formattedDate = formatDate(expirationDate)

  if (!formattedDate) return ''

  if (config.subscription?.will_cancel) {
    return `Expires: ${formattedDate}`
  }

  return `Next Payment: ${formattedDate}`
}

export const getPlanById = (config) => {
  const { subscription = {}, plans = [] } = config
  const { plan_id } = subscription

  return plans.find((item) => item.id === plan_id)
}

export const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}