<script setup>
import { ref, computed, watchEffect, provide, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

import { useGlobalStore } from "@/stores/GlobalStore";
import { useStrapiStore } from "@/stores/StrapiStore";
import Header from "./Header/index.vue";
const AdminBanner = defineAsyncComponent(() => import("./components/AdminBanner.vue"));
const NotificationBar = defineAsyncComponent(() =>
  import("./components/NotificationBar.vue")
);
const Sidebar = defineAsyncComponent(() => import("./Sidebar/index.vue"));
const FeedbackDialog = defineAsyncComponent(() => import("./FeedbackDialog/index.vue"));

const globalStore = useGlobalStore();
const strapiStore = useStrapiStore();

const route = useRoute();

const sidebar = computed(() => globalStore.sidebar);
const windowWidth = computed(() => globalStore.windowWidth);
const showAdminBanner = computed(() => globalStore.showAdminBanner);
const showSystemNotificationBar = computed(
  () => Object.keys(strapiStore.notificationStatus).length !== 0
);
const systemNotificationBar = computed(() => strapiStore.notificationStatus);
const showingAside = computed(() => {
  const excludedRoutes = [
    "export-batch",
    "create-batch",
    "AddTypes",
    "AddSides",
    "AddUpload",
    "AddCert",
  ];
  return !excludedRoutes.some(
    (routePart) => route.fullPath.includes(routePart) || route.name === routePart
  );
});

const showFeedbackDialog = ref({
  open: false,
});

const closeSidebar = () => {
  if (windowWidth.value < 1280) {
    globalStore.sidebar = false;
    localStorage.setItem("sidebar", JSON.stringify(globalStore.sidebar));
  }
};

const closeTabletSidebar = () => {
  if (windowWidth.value >= 768 && windowWidth.value < 1280) {
    if (globalStore.sidebar) {
      globalStore.sidebar = false;
      localStorage.setItem("sidebar", JSON.stringify(globalStore.sidebar));
    }
  }
};

provide("showFeedbackDialog", showFeedbackDialog);
provide("showingAside", showingAside);
provide("closeSidebar", closeSidebar);
provide("showSystemNotificationBar", showSystemNotificationBar);
provide("systemNotificationBar", systemNotificationBar);

watchEffect(() => {
  const isHomepageOrRelated = [
    "Homepage",
    "Batches",
    "Inventory",
    "Connections",
  ].includes(route.name);

  if (!localStorage?.sidebar) {
    globalStore.sidebar = isHomepageOrRelated;
  }
});
</script>

<template>
  <NotificationBar />
  <AdminBanner />
  <section
    class="h-full flex pt-12 transition-all duration-300"
    :class="{
      'xl:!pl-72': sidebar,
      '!pt-[88px]': showAdminBanner || showSystemNotificationBar,
      'md:pl-[60px]': showingAside,
      'md:!pl-72': sidebar,
    }"
  >
    <section class="main-layout" v-click-outside="closeTabletSidebar">
      <Header />
      <Sidebar />
    </section>
    <section class="flex flex-col h-full flex-grow" :class="{'bg-[#F9FAFB]': route.path === '/connections/ebay/connect' || route.path === '/connections/ebay/connected' || route.path === '/connections/whatnot/connect'  || route.path === '/connections/whatnot/connected'}">
      <RouterView />
    </section>
  </section>

  <FeedbackDialog />
</template>
