// Logos
import collxLogo from '@/assets/images/logos/collx.svg'
import ebayLogo from '@/assets/images/logos/ebay.svg'
import authEbayLogo from '@/assets/images/auth-ebay.svg'
import shopifyLogo from '@/assets/images/logos/shopify.svg'
import whatnotLogo from '@/assets/images/logos/whatnot.svg'
import whatnotLogoMini from '@/assets/images/logos/whatnot-mini.svg'
import collxLogoMini from '@/assets/images/logos/collx-mini.svg'
import collxLogoMiniDisabled from '@/assets/images/logos/collx-mini-disabled.svg'
import ebayLogoMiniDisabled from '@/assets/images/logos/ebay-mini-disabled.svg'
import ebayLogoMini from '@/assets/images/logos/ebay-mini.svg'
import shopifyLogoMini from '@/assets/images/logos/shopify-mini.svg'
import simpleAuctionLogo from '@/assets/images/logos/simple-auction.svg'
import moment from 'moment'

export const TABS = [
  { text: 'Overview', route: '/connections/ebay', key: 'overview' },
  { text: 'eBay Settings', route: '/connections/ebay/connection-settings', key: 'connection_settings' },
  { text: 'My Locations', route: '/connections/ebay/my-locations', key: 'my_locations' },
  { text: 'Export Templates', route: '/connections/ebay/templates', key: 'templates'},
  { text: 'Marketplace Templates', route: '/connections/ebay/marketplace-templates', key: 'marketplace_templates'},
  { text: 'History', route: '/connections/ebay/history', key: 'history' },
]

export const COLLX_CONNECTION_TABS = [
  { text: 'Overview', route: '/connections/collx', key: 'overview' },
  // { text: 'Listing Information', route: '/connections/collx/listing-information', key: 'listing_information' },
  { text: 'CollX Settings', route: '/connections/collx/connection-settings', key: 'connection_settings' },
  { text: 'Export History', route: '/connections/collx/export-history', key: 'export_history' },
]

export const SHOPIFY_CONNECTION_TABS = [
  { text: 'Overview', route: '/connections/shopify' },
  { text: 'Export Settings', route: '/connections/shopify/export-settings', key: 'export_settings'},
  { text: 'History', route: '/connections/shopify/export-history' },
]

export const WHATNOT_CONNECTION_TABS = [
  { text: 'Overview', route: '/connections/whatnot' },
  { text: 'Whatnot Settings', route: '/connections/whatnot/connection-settings', key: 'connection_settings' },
  { text: 'Marketplace Templates', route: '/connections/whatnot/marketplace-templates', key: 'marketplace_templates'},
  { text: 'History', route: '/connections/whatnot/history' },
]

export const LISTING_PRICES = ['Standard Price', 'Calculated Price', 'Price Override']

export const COLLX_LISTING_PRICES = ['Sale Price', 'Calculated Price', 'Price Override']

export const INCREASE_OPTIONS = ['Fixed Amount', 'Percentage']

export const SHIPPING_SERVICES = [
  {
    headerName: 'Economy',
    options: ['eBay Standard Envelope', 'Economy Shipping'],
  },
  {
    headerName: 'Standard',
    options: [
      'USPS Standard Post',
      'USPS Priority Mail',
      'USPS Ground Advantage',
      'FedEx Ground',
    ],
  },
  {
    headerName: 'Expedited',
    options: ['FedEx Express Saver', 'FedEx 2day', 'UPS 2 Day', 'UPS 3 Day'],
  },
  {
    headerName: 'One-Day services',
    options: ['FedEx Overnight', 'UPS Next Day'],
  },
]

export const HANDLING_TIME = [
  {
    headerName: 'Regular',
    options: {
      0: 'Same Business Day',
      1: '1 day',
      2: '2 days',
      3: '3 days',
    },
  },
  {
    headerName: 'Extended',
    options: {
      4: '4 days',
      5: '5 days',
      10: '10 days',
      15: '15 days',
      20: '20 days',
      30: '30 days',
    },
  },
]

export const HANDLING_TIME_VALUES = {
  0: 'Same Business Day',
  1: '1 day',
  2: '2 days',
  3: '3 days',
  4: '4 days',
  5: '5 days',
  10: '10 days',
  15: '15 days',
  20: '20 days',
  30: '30 days',
}

export const FORM_CONNECTION_OPTIONS = [
  {
    label: 'Within',
    placeholder: 'Please select',
    field: 'returns_within',
    options: {
      14: '14 Days',
      30: '30 Days',
      60: '60 Days',
    },
  },
  {
    label: 'Refund',
    placeholder: 'Please select',
    field: 'refund_given_as',
    options: {
      'Money Back': 'Money Back',
      'Money Back or Replacement (Buyers Choice)': 'Money Back or Replacement (Buyers Choice)',
      'Money Back or Replacement (Sellers Choice)': 'Money Back or Replacement (Sellers Choice)',
    },
  },
  {
    label: 'Shipping Cost Paid By',
    placeholder: 'Please select',
    field: 'return_shipping_paid_by',
    tooltip: `
      Tip: To use this feature, you first need to enable eBay’s Return Process on eBay by going to <br/>
      My eBay > Account > Site Preferences > Return Preferences,
      and then setting ‘Do you want to use eBay’s return process?’ to Yes.
    `,
    options: {
      Buyer: 'Buyer',
      Seller: 'Seller',
    },
  },
]

export const FORM_CONNECTION_OPTIONS_VALUES = {
  14: '14 Days',
  30: '30 Days',
  60: '60 Days',
  'Money Back': 'Money Back',
  'Money Back or Replacement (Buyers Choice)': 'Money Back or Replacement (Buyers Choice)',
  'Money Back or Replacement (Sellers Choice)': 'Money Back or Replacement (Sellers Choice)',
  Buyer: 'Buyer',
  Seller: 'Seller',
}

export const DEFAULT_TEMPLATE = {
  id: null,
  user_id: null,
  name: '',
  type: 'ebay',
  listing_type: 'fixed',
  listing_price: true,
  fixed_amount_to_price: 0,
  percent_to_price: 0,
  allow_best_offer: false,
  duration: 'GTC',
  handling_time: null,
  shipping_service: '',
  free_shipping: false,
  shipping_cost: 0,
  additional_items: 0,
  returns: true,
  returns_within: null,
  refund_given_as: '',
  return_shipping_paid_by: '',
  additional_return_info: '',
  default: false,
  auto_accept_offer: false,
  auto_decline_offer: false,
  calculated_method: true,
  enable_buy_it_now_price: true,
  start_price: 0,
  buy_it_now_price: 0,
  ebay_category: 261328,
  store_category: 0,
  city: '',
  state: '',
  zip_code: '',
  return_profile_name: '',
  shipping_profile_name: '',
  immediate_payment: false,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  make_live_immediately: true,
  schedule_date: moment().format('YYYY-MM-DD'),
  schedule_time: moment().format('hh:mm:ss'),
  set_for_all: true,
  schedule_interval: 'Seconds',
  schedule_delay: 0,
  price_adjustment_type: 'increase',
  use_cert_as_sku: false,
  make_live_immediately: true,
  sudden_death: false
}

export const EBAY_CATEGORIES = {
  261328: 'Sports Trading Cards - Trading Card Singles (#261328)',
  183454: 'Collectible Card Games - Trading Card Singles (#183454)',
  183050: 'Non-Sports Trading Cards - Trading Card Singles (#183050)',
}

export const CATEGORIES = {
  261328: 'Sports Trading Cards - Trading Card Singles (#261328)',
  183454: 'Collectible Card Games - Trading Card Singles (#183454)',
  183050: 'Non-Sports Trading Cards - Trading Card Singles (#183050)',
}

export const LISTING_DURATION = [ 'GTC' ]
export const LISTING_DURATION_AUCTION = ['7', '10', '30', '5', '3', '1' ]
export const LISTING_DURATION_VIEW = {
  1: '1 day',
  3: '3 days',
  5: '5 days',
  7: '7 days',
  10: '10 days',
  30: '30 days',
  GTC: 'Good Till Canceled (GTC)'
}

export const EBAY_CHANNEL = {
  authLogo: authEbayLogo,
  logo: ebayLogo,
  miniLogo: ebayLogoMini,
  miniLogoDisabled: ebayLogoMiniDisabled,
  name: 'eBay',
  beta: true,
  full_name: 'eBay Direct Connection',
  label: 'eBay Marketplace',
  key: 'ebay',
  keyField: 'ebay',
  description: 'Increase sales by creating listings that reaching millions of shoppers on eBay',
  infoRoute: '/connections/ebay',
  connected: true,
  url: 'https://www.ebay.com/',
  field: 'ebay_enabled',
  checked: false,
  listedField: 'ebay_listed_at',
  profileName: 'ebay_profile',
  marketplaceTemplatesField: 'marketplaceListingTemplates',
  urlToTemplates: '/connections/ebay/marketplace-templates'
}

export const WHATNOT_CHANNEL = {
  authLogo: whatnotLogo,
  logo: whatnotLogo,
  miniLogo: whatnotLogoMini,
  miniLogoDisabled: whatnotLogoMini,
  name: 'Whatnot',
  full_name: 'Whatnot Listing Template',
  label: 'Whatnot Marketplace',
  key: 'whatnot',
  keyField: 'whatnot',
  description: 'Increase sales by creating listings that reaching millions of shoppers on Whatnot',
  infoRoute: '/connections/whatnot',
  connected: true,
  url: 'https://www.whatnot.com/',
  field: 'whatnot_enabled',
  checked: false,
  listedField: 'whatnot_listed_at',
  profileName: 'whatnot_profile',
  marketplaceTemplatesField: 'whatnotListingTemplates',
}

export const SHOPIFY_CHANNEL = {
  authLogo: shopifyLogo,
  logo: shopifyLogo,
  miniLogo: shopifyLogoMini,
  miniLogoDisabled: shopifyLogoMini,
  name: 'Shopify',
  full_name: 'Shopify CSV Export',
  label: 'Shopify',
  key: 'shopify',
  keyField: 'shopify',
  description: 'Sell anywhere with low rates and predictable fees',
  infoRoute: '/connections/shopify',
  connected: true,
  url: 'https://www.shopify.com/',
  field: 'shopify_enabled',
  checked: false,
  listedField: 'shopify_listed_at',
  profileName: ''
}

export const COLLX_CHANNEL = {
  authLogo: collxLogo,
  logo: collxLogo,
  miniLogo: collxLogoMini,
  miniLogoDisabled: collxLogoMiniDisabled,
  name: 'CollX',
  full_name: 'CollX Direct Connection',
  label: 'CollX Marketplace',
  key: 'collx',
  keyField: 'collx',
  description: 'The fastest growing marketplace for trading cards',
  infoRoute: '/connections/collx',
  connected: true,
  url: 'https://www.collx.app/',
  field: 'collx_enabled',
  checked: false,
  listedField: 'collx_listed_at',
  profileName: '',
  marketplaceTemplatesField: 'collxListingTemplates',
  urlToTemplates: '/connections/collx/connect/listing-information'
}

export const SIMPLE_AUCTION_CHANNEL = {
  logo: simpleAuctionLogo,
  name: 'Simple Auction',
  description: 'Increase sales by creating listings that reaching millions of shoppers on eBay',
  infoRoute: '/connections/simple-auction',
  connected: true,
  url: 'https://simpleauctionsite.com/',
  field: 'simple_auction_enabled',
  checked: false
}

export const RECOMMENDED_CONNECTION_CHANNELS = [
  { ...COLLX_CHANNEL },
  { ...EBAY_CHANNEL },
  // { ...WHATNOT_CHANNEL },
  { ...SHOPIFY_CHANNEL },
  // { ...SIMPLE_AUCTION_CHANNEL }
]

export const ALL_CONNECTION_CHANNELS = [
  { ...COLLX_CHANNEL },
  { ...EBAY_CHANNEL },
  // { ...WHATNOT_CHANNEL },
  { ...SHOPIFY_CHANNEL },
  // { ...SIMPLE_AUCTION_CHANNEL }
]

export const PUSHER_EVENTS_TO_CHANNELS = {
  collx_import_completed: { name: 'CollX', channel: COLLX_CHANNEL },
  ebay_import_completed: { name: 'eBay', channel: EBAY_CHANNEL },
  ebay_import_failed: { name: 'eBay', channel: EBAY_CHANNEL },
  whatnot_import_completed: { name: 'Whatnot', channel: WHATNOT_CHANNEL }
}

export const CHANNELS_BY_TYPE = {
  collx: { name: 'CollX', channel: COLLX_CHANNEL },
  ebay: { name: 'eBay', channel: EBAY_CHANNEL },
  'ebay-direct': { name: 'eBay', channel: EBAY_CHANNEL },
  whatnot: { name: 'Whatnot', channel: WHATNOT_CHANNEL }
}

export const EBAY_CONNECTION_STEPS = [
  { text: 'Connect eBay Account', route: '/connections', checked: false, routeLabel: 'connect' },
  { text: 'Marketplace Listing', route: '/connections', checked: false, routeLabel: 'marketplace-listing-information' },
  { text: 'Select eBay Policies', route: '/connections', checked: false, routeLabel: 'policies' },
]

export const WHATNOT_CONNECTION_STEPS = [
  { text: 'Connect Whatnot Account', route: '/connections', checked: false, routeLabel: 'connect' },
  { text: 'Listing Information', route: '/connections', checked: false, routeLabel: 'listing-information' },
  { text: 'Select Whatnot Policies', route: '/connections', checked: false, routeLabel: 'policies' },
]

export const CONNECTION_STEPS = {
  ebay: EBAY_CONNECTION_STEPS,
  whatnot: WHATNOT_CONNECTION_STEPS,
}

export const EXPORT_CONNECTION_STEPS = [
  { text: 'eBay Listing Information', route: '/connections', checked: false, routeLabel: 'listing-information' },
  { text: 'eBay Shipping Settings', route: '/connections', checked: false, routeLabel: 'shipping-settings' },
  { text: 'eBay Payment & Return Settings', route: '/connections', checked: false, routeLabel: 'payment-return-settings' },
]

export const COLLX_CDP_COMPARISON_TABLE = [
  { collx_key: 'CollX', cdp_key: 'Card dealer pro' },
  { collx_key: 'Product Type', cdp_key: 'Type' },
  { collx_key: 'Sport / Game', cdp_key: 'Category' },
  { collx_key: 'Graded', cdp_key: 'Graded' },
  { collx_key: 'Grader', cdp_key: 'Grader' },
  { collx_key: 'Cert No.', cdp_key: 'Certificate No.' },
  { collx_key: 'Condition', cdp_key: 'Condition' },
  { collx_key: 'Purchase Price', cdp_key: 'Purchase Price' },
  { collx_key: 'Purchase Date', cdp_key: 'Purchase Date' },
  { collx_key: 'Name', cdp_key: 'Player Name' },
  { collx_key: 'Set', cdp_key: 'Set' },
  { collx_key: 'Year', cdp_key: 'Year' },
  { collx_key: 'Brand', cdp_key: 'Brand' },
  { collx_key: 'Card Number', cdp_key: 'Card Number' },
  { collx_key: 'Subset / Parallel', cdp_key: 'Subset / Parallel' },
  { collx_key: 'Variation', cdp_key: 'Variation' },
  { collx_key: 'Team', cdp_key: 'Team' },
  { collx_key: 'Attributes', cdp_key: 'Attributes' },
  { collx_key: 'Notes', cdp_key: 'Description' },
]

export const COLLX_LABEL_SIZE = {
  FOUR_BY_SIX: '4" x 6"',
  EIGHT_AND_A_HALF_BY_ELEVEN: '8.5" x 11"'
}

export const CALCULATION_TYPES = ['Increase', 'Decrease']
export const CALCULATION_METHODS = ['$', '%']

export const POLICIES_IDS_NAMES_IN_TEMPLATE = {
  locations: 'ebay_location_id',
  fulfillmentPolicies: 'ebay_fulfillment_policy_id',
  paymentPolicies: 'ebay_payment_policy_id',
  returnPolicies: 'ebay_return_policy_id',
  shippingProfile: 'ebay_shipping_profile_id',
}

export const POLICIES_IDS_NAMES_IN_POLICY_LIST = {
  locations: 'locationId',
  fulfillmentPolicies: 'fulfillmentPolicyId',
  paymentPolicies: 'paymentPolicyId',
  returnPolicies: 'returnPolicyId'
}

export const HEADER_CONNECTION_AVAILABLE_FOR_STEPS = [
  '/connections/ebay/export/listing-information', 
  '/connections/ebay/connect', 
  '/connections/ebay/policies', 
  '/connections/ebay/marketplace-listing-information', 
  '/connections/ebay/export/shipping-settings', 
  '/connections/ebay/export/payment-return-settings',
  '/connections/whatnot/connect', 
  '/connections/whatnot/listing-information', 
  '/connections/whatnot/policies', 
]

export const NEW_TEMPLATE_NAMES = {
  ebay: 'New eBay Listing Template',
  whatnot: 'My Whatnot Listing Template'
}

export const INTERVAL_OPTIONS = [ 'Minutes', 'Seconds' ]

export const ALLOWED_EBAY_TEMPLATE_FIELDS = {
  type: 'listingTemplates',
  connection: 'ebay',
  label: 'eBay Category Number',
  bestOffer: true,
  duration: true,
  listingSchedule: true,
  sku: true,
  allowedShipping: true,
  allowedPayment: true,
  allowedReturn: true,
  enableBuyItNow: true,
}

export const ALLOWED_EBAY_DIRECT_TEMPLATE_FIELDS = {
  type: 'marketplaceListingTemplates',
  connection: 'ebay',
  label: 'eBay Category Number',
  bestOffer: true,
  duration: true,
  listingSchedule: true,
  sku: true,
  allowedShipping: true,
  allowedPayment: true,
  allowedReturn: true,
  enableBuyItNow: true,
}

export const ALLOWED_WHATNOT_TEMPLATE_FIELDS = {
  type: 'whatnotListingTemplates',
  connection: 'whatnot',
  label: 'Whatnot Category',
  suddenDeath: true,
  acceptOffer: true,
  publishListing: true,
  time: true
}

export const POLICY_LABELS = [
  {
    type: 'locations',
    label: 'Locations',
    buttonLabel: 'CDP Default Location',
    addButtonLabel1: 'New Location',
    addButtonLabel2: '',
    addLabel: 'Item Location',
    field: 'ebay_location_id'
  },
  {
    type: 'fulfillmentPolicies',
    label: 'Fulfillment Policy',
    buttonLabel: 'CDP Default Fulfillment Policy',
    addButtonLabel1: 'Add Fulfillment Policy',
    addButtonLabel2: 'Enter fulfillment info below',
    addLabel: 'Fulfillment',
    field: 'ebay_fulfillment_policy_id'
  },
  {
    type: 'paymentPolicies',
    label: 'Payment Policy',
    buttonLabel: 'CDP Default Payment Policy',
    addButtonLabel1: 'Add Payment Policy',
    addButtonLabel2: 'Enter payment info below',
    addLabel: 'Payment',
    field: 'ebay_payment_policy_id'
  },
  {
    type: 'returnPolicies',
    label: 'Return Policy',
    buttonLabel: 'CDP Default Return Policy',
    addButtonLabel1: 'Add Return Policy',
    addButtonLabel2: 'Enter return info below',
    addLabel: 'Item Return',
    field: 'ebay_return_policy_id'
  },
  {
    type: 'shippingProfile',
    label: 'Shipping Profile',
    buttonLabel: 'CDP Default Shipping Profile',
    addButtonLabel1: 'Add Return Policy',
    addButtonLabel2: 'Enter return info below',
    addLabel: 'Item Return',
  },
] 

export const EXPORT_CONNECTION_TYPES = {
  'ebay': {
    name: 'CSV Export',
    icon: ebayLogoMini
  },
  'ebay-direct': {
    name: 'Direct Connection',
    icon: ebayLogoMini
  },
  'collx': {
    name: 'CollX',
    icon: collxLogoMini
  },
  'shopify': {
    name: 'Shopify',
    icon: shopifyLogoMini
  },
  'whatnot': {
    name: 'Whatnot',
    icon: whatnotLogoMini
  },
}

export const DEFAULT_LOCATION = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'US',
  postalCode: '',
  stateOrProvince: '',
  name: ''
}

export const US_STATES = [
  { abbreviation: "AL", name: "Alabama" },
  { abbreviation: "AK", name: "Alaska" },
  { abbreviation: "AZ", name: "Arizona" },
  { abbreviation: "AR", name: "Arkansas" },
  { abbreviation: "CA", name: "California" },
  { abbreviation: "CO", name: "Colorado" },
  { abbreviation: "CT", name: "Connecticut" },
  { abbreviation: "DE", name: "Delaware" },
  { abbreviation: "DC", name: "District of Columbia" },
  { abbreviation: "FL", name: "Florida" },
  { abbreviation: "GA", name: "Georgia" },
  { abbreviation: "HI", name: "Hawaii" },
  { abbreviation: "ID", name: "Idaho" },
  { abbreviation: "IL", name: "Illinois" },
  { abbreviation: "IN", name: "Indiana" },
  { abbreviation: "IA", name: "Iowa" },
  { abbreviation: "KS", name: "Kansas" },
  { abbreviation: "KY", name: "Kentucky" },
  { abbreviation: "LA", name: "Louisiana" },
  { abbreviation: "ME", name: "Maine" },
  { abbreviation: "MD", name: "Maryland" },
  { abbreviation: "MA", name: "Massachusetts" },
  { abbreviation: "MI", name: "Michigan" },
  { abbreviation: "MN", name: "Minnesota" },
  { abbreviation: "MS", name: "Mississippi" },
  { abbreviation: "MO", name: "Missouri" },
  { abbreviation: "MT", name: "Montana" },
  { abbreviation: "NE", name: "Nebraska" },
  { abbreviation: "NV", name: "Nevada" },
  { abbreviation: "NH", name: "New Hampshire" },
  { abbreviation: "NJ", name: "New Jersey" },
  { abbreviation: "NM", name: "New Mexico" },
  { abbreviation: "NY", name: "New York" },
  { abbreviation: "NC", name: "North Carolina" },
  { abbreviation: "ND", name: "North Dakota" },
  { abbreviation: "OH", name: "Ohio" },
  { abbreviation: "OK", name: "Oklahoma" },
  { abbreviation: "OR", name: "Oregon" },
  { abbreviation: "PA", name: "Pennsylvania" },
  { abbreviation: "RI", name: "Rhode Island" },
  { abbreviation: "SC", name: "South Carolina" },
  { abbreviation: "SD", name: "South Dakota" },
  { abbreviation: "TN", name: "Tennessee" },
  { abbreviation: "TX", name: "Texas" },
  { abbreviation: "UT", name: "Utah" },
  { abbreviation: "VT", name: "Vermont" },
  { abbreviation: "VA", name: "Virginia" },
  { abbreviation: "WA", name: "Washington" },
  { abbreviation: "WV", name: "West Virginia" },
  { abbreviation: "WI", name: "Wisconsin" },
  { abbreviation: "WY", name: "Wyoming" },
  { abbreviation: "PR", name: "Puerto Rico" },
  { abbreviation: "VI", name: "Virgin Islands" },
  { abbreviation: "AS", name: "American Samoa" },
  { abbreviation: "GU", name: "Guam" },
  { abbreviation: "MP", name: "Northern Mariana Islands" },
  { abbreviation: "AA", name: "Armed Forces Americas" },
  { abbreviation: "AE", name: "Armed Forces Europe" },
  { abbreviation: "AP", name: "Armed Forces Pacific" }
]

export const EXCLUDED_ERROR_MESSAGES = [
  'An active subscription already exists',
  'no account found for',
  'This link has expired',
  'This link is not valid',
  'Failed to create fulfillment policy',  
  'Failed to create return policy',
  'Failed to create payment policy',
]


