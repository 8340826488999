import { defineStore } from 'pinia'

import { apiClient } from "@/helpers/api.js"
import { useBatchStore } from '@/stores/BatchStore'

export const useSearchStore = defineStore('SearchStore', {
  state: () => {
    return {
      comboboxDialog: false,
      selectedFilter: '',
      recentSearches: [ 'Mike Trout', 'Mike Trou', 'Mike Tro', 'Mike Tr', 'Mike T', 'Mike', 'Mik', 'Mi', 'M' ],
      searchState: 'start',

      batchesSearched: [],
      recentlyModifiedBatches: [],
      cdp_search: {
        open: false,
        state: '',
        locationOfSearchCall: ''
      },

      loadingBatches: false,
      loadingMatches: false,

      hoveredOverMatchCard: null,

      matchesData: {
        matches: []
      },

      triggerOpenSearchResult: false
    }
  },

  actions: {
    updateSearchStateBasedOnDataObject(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object') {
          Object.entries(data).forEach(([key, value]) => {
            this[key] = value
          })
        } else {
          this[key] = value
        }
      })
    },
    async searchUserBatches(options = {}) {
      try {
        const { query, kind } = options

        this.loadingBatches = true

        const params = {}

        if (query) {
          params.q = query
        }

        if (kind) {
          params.kind = kind
        }

        const { data } = await apiClient().get(`/batches`, query || kind ? {
          params
        } : {})

        if (!query) {
          this.recentlyModifiedBatches = data.items.sort((a, b) => {
            const dateA = new Date(a.updated_at)
            const dateB = new Date(b.updated_at)
            return dateB - dateA; // Sorting in descending order
          }).slice(0, 5)
        } else {
          this.recentlyModifiedBatches = []
        }
        this.batchesSearched = data.items
        this.loadingBatches = false
      } catch (error) {
        console.log('searchUserBatches error', error)
        this.loadingBatches = false
      }
    },

    async searchDatabaseMatches(options = {}) {
      const { query, page = 1, kind } = options
      const batchStore = useBatchStore() // XXX What is selectedBatchDetailsViewOption?
      try {
        if (!query && this.selectedFilter === 'Card Database' && batchStore.selectedBatchDetailsViewOption === 'Inspector') {
          this.matchesData = {
            matches: []
          }
          return 
        }
        
        if (!this.matchesData.matches.length) {
          this.loadingMatches = true
        }

        const params = {
          page
        }

        if (query) {
          params.q = query
        }

        if (kind) {
          params.kind = kind
        }

        if (options.baseSetIds) {
          params.baseSetIds = options.baseSetIds
        }

        if (options.setIds) {
          params.setIds = options.setIds
        }

        const { data } = await apiClient(true).get(`/search`, query || kind ? {
          params
        } : {})
        if (page > 1) {
          this.matchesData = { ...data, matches: [...this.matchesData.matches, ...data.matches] }
        } else {
          this.matchesData = { ...data, matches: data.matches ? [...data.matches] : [] }
        }

        this.loadingMatches = false
      } catch (error) {
        this.loadingMatches = false
        console.log('searchDatabaseMatches error', error)
      }
    },

  }
})