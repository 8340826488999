import { icons } from '@/helpers/icons'

export const CATEGORIES_FILTER_ITEM = { icon: { is_local: true, value: icons.icon_multisport }, name: 'Categories', field: 'categories', key_name: 'categories' }
export const BRAND_FILTER_ITEM = { icon: 'heroicons:tag', name: 'Brand', field: 'brand', key_name: 'brand' }
export const ATTRIBUTE_FILTER_ITEM = { icon: 'heroicons:information-circle', name: 'Attribute', field: 'attributes', key_name: 'attribute' }
export const GRADED_FILTER_ITEM = { icon: 'heroicons:scale-20-solid', name: 'Graded', field: 'graded', key_name: 'graded' }
export const GRADE_FILTER_ITEM = { icon: 'heroicons:scale-20-solid', name: 'Grader', field: 'grader', key_name: 'grader' }

export const LISTINGS_FILTERS_BLOCK_1 = [
  { icon: { is_local: true, value: icons.status_spinner }, name: 'Status', key_name: 'status' },
  // { icon: { is_local: true, value: icons.text_T }, name: 'Content' }
]

export const LISTINGS_FILTERS_BLOCK_2 = [
  CATEGORIES_FILTER_ITEM,
  BRAND_FILTER_ITEM,
  ATTRIBUTE_FILTER_ITEM,
  GRADED_FILTER_ITEM,
  GRADE_FILTER_ITEM
  // { icon: 'heroicons:currency-dollar', name: 'Price' },
  // { icon: 'heroicons:map-pin', name: 'Location' },
]

export const LISTINGS_FILTERS = {
  filter_blocks: [
    LISTINGS_FILTERS_BLOCK_1,
    LISTINGS_FILTERS_BLOCK_2,
  ]
}

export const PRODUCT_DETAILS_FIELDS = [
  {
    field: 'set',
    label: 'Set',
    type: 'text',
  },
  {
    field: 'team',
    label: 'Team',
    type: 'text',
  },
  {
    field: 'year',
    label: 'Year',
    type: 'number',
  },
  {
    field: 'brand',
    label: 'Brand',
    type: 'text',
  },
  {
    field: 'card_number',
    label: 'Card Number',
    type: 'number',
  },
  {
    field: 'player',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'attributes',
    label: 'Attributes',
    type: 'text',
  },
  {
    field: 'sports',
    label: 'Category',
    type: 'text',
  },
  {
    field: 'subset',
    label: 'Subset / Parallel',
    type: 'text'
  },
  {
    field: 'variation',
    label: 'Variation',
    type: 'text',
  },
  {
    field: 'serial_number',
    label: 'Serial Number',
    type: 'text',
  },
]

export const LISTING_STATUS = {
  'accepting_offers': {
    key: 'accepting_offers',
    label: 'Accepting Offers',
    icon: 'heroicons:hand-money',
  },
  'for_sale': {
    key: 'for_sale',
    label: 'For Sale',
    icon: 'heroicons:tag',
  },
  'sold_out': {
    key: 'sold_out',
    label: 'Sold Out',
    icon: 'heroicons:tag',
  },
  'sold_on': {
    key: 'sold_on',
    label: 'Sold On',
    icon: 'heroicons:tag',
  },
  'ended': {
    key: 'ended',
    label: 'Ended',
    icon: 'heroicons:tag',
  },
}
