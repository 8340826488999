<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

const restartApp = () => {
  router.push('/')
  setTimeout(() => {
    window.location.reload()
  }, 30);
}
</script>

<template>
  <section class="w-[50rem] flex flex-col items-center justify-center gap-y-14 h-full flex-1 text-center text-sm mx-auto">
    <img src="@/assets/images/cdp-logo-large-3.svg" class="w-40 absolute top-40" />

    <section>
      <img src="@/assets/3d-assets/icons/maintenance.png" class="h-64 mb-8 mx-auto" />

      <section>
        <h1 class="text-3xl font-bold mb-1 leading-normal">We'll Be Right Back!</h1>
        <p class="text-lg text-gray-500 max-w-2xl">
          We're currently performing some maintenance to improve your experience. Our site will be back online shortly. Thanks for your patience! 
        </p>
      </section>
    </section>

    <section class="flex gap-2 absolute bottom-16">
      <p class="text-gray-500">If you need assistance, please contact us at</p>
      <a href="mailto:support@carddealerpro.com" class="tinted-link">support@carddealerpro.com</a>
    </section>
    
  </section>
</template>