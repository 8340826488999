export const updateSearchTermsForUnmatchedCard = (card) => {
  let q = []
  if (card?.set) {
    q.push(card.set)
  } else if (card?.brand) {
    q.push(card.brand)
  }

  if (card?.subset) {
    q.push(card.subset)
  }

  if (card?.card_number) {
    q.push(`#${card.card_number}`)
  }

  if (card?.player) {
    q.push(card.player)
  }

  if (card?.attributes) {
    q.push(card.attributes)
  }

  return {
    searchTermForUnmatchedCard_: q.join(' '),
    searchTerms_: q.join(' ')
  }
}

export const getLockSetQueryKey = (batchStore) => {
  let lockSetQueryKey = 'baseSetIds'

  if (batchStore.selectedBatch?.lock_id && batchStore.selectedBatch?.lock_includes_parallels) {
    lockSetQueryKey = 'baseSetIds'
  } else if (batchStore.selectedBatch?.lock_id && !batchStore.selectedBatch?.lock_includes_parallels) {
    lockSetQueryKey = 'setIds'
  }

  return lockSetQueryKey
}